import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { moveFromBottom, moveFromTop } from 'ngx-router-animations';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { State } from './store';
import { selectIsInteractive } from './store/selectors';
import { MenuComponent } from './utils/menu/menu.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('moveFromBottom', [
      transition('home => donut-cop', useAnimation(moveFromBottom)),
      transition('donut-cop => naviy', useAnimation(moveFromBottom)),
      transition('naviy => team-shane', useAnimation(moveFromBottom)),
      transition('team-shane => team-john', useAnimation(moveFromBottom)),
      transition('team-john => news', useAnimation(moveFromBottom)),
      transition('news => contact', useAnimation(moveFromBottom)),
      transition('donut-cop => home', useAnimation(moveFromTop)),
      transition('naviy => donut-cop', useAnimation(moveFromTop)),
      transition('team-shane => naviy', useAnimation(moveFromTop)),
      transition('team-john => team-shane', useAnimation(moveFromTop)),
      transition('news => team-john', useAnimation(moveFromTop)),
      transition('contact => news', useAnimation(moveFromTop)),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  currentPage = PagesEnum.home;
  loading = true;
  loaded$: Observable<boolean>;
  isInteractive$: Observable<boolean>;
  isDisabled: boolean = false;
  private subs: Subscription;

  constructor(private router: Router, private store: Store<State>) {
    this.subs = this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
    this.loaded$ = this.router.events.pipe(
      map((ev) => !(ev instanceof NavigationStart))
    );
    this.isInteractive$ = this.store.pipe(select(selectIsInteractive));
    this.subs.add(
      this.isInteractive$.subscribe((interactive) => {
        this.isDisabled = !interactive;
      })
    );
  }

  ngOnInit() {
    window.onwheel = (ev: WheelEvent) => {
      if (this.router.url.search('blog') === -1) {
        this.scrollNavigation(ev);
      }
    };
    window.ontouchmove = (ev: any) => {
      if (this.router.url.search('blog') === -1) {
        this.scrollNavigation(ev);
      }
    };
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @ViewChild(MenuComponent) menu!: MenuComponent;

  closeMenu(event: Event) {
    const target: HTMLElement = (event.target ||
      event.srcElement ||
      event.currentTarget) as HTMLElement;
    const targetElement = target.parentElement.querySelector('.bg-wrapper');
    if (this.isDisabled && targetElement) {
      this.menu.closeMenu();
    }
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  private scrollNavigation(ev: WheelEvent) {
    if (document.querySelector('.branding-open')) {
      return;
    }
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      ev.deltaY > 0
    ) {
      this.navigateBottom();
    } else if (window.scrollY === 0 && ev.deltaY < 0) {
      this.navigateTop();
    }
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }

  private menuClassMapping = {
    home: 'menu-white',
    games: 'menu-black',
    'donut-cop': 'menu-black',
    naviy: 'menu-black',
    about: 'menu-black',
    'team-shane': 'menu-black',
    'team-john': 'menu-black',
    news: 'menu-black',
    contact: 'menu-none',
    '': '',
  };

  getMenuClass(outlet) {
    console.log(this.router.url);
    this.currentPage = this.router.url.substring(1) as any;
    return this.menuClassMapping[outlet.activatedRouteData.state];
  }

  navigateBottom() {
    console.log(this.currentPage);
    console.log(PagesEnum[this.currentPage]);
    console.log(PagesEnum[PagesEnum[this.currentPage] + 1]);
    this.router.navigateByUrl(PagesEnum[PagesEnum[this.currentPage] + 1]);
  }
  navigateTop() {
    this.router.navigateByUrl(
      parseInt(PagesEnum[this.currentPage], 10) > 1
        ? PagesEnum[parseInt(PagesEnum[this.currentPage], 10) - 1]
        : 'home'
    );
  }
}

enum PagesEnum {
  'home',
}
