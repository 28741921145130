import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { DisableUI, EnableUI } from 'src/app/store/actions';

export interface MenuItem {
  id: string;
  title: string;
  description?: string;
  children?: MenuItem[];
  routerLink?: string;
  externalLink?: string;
  callback?: () => void;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, AfterViewInit {
  @Input()
  public logoLink = '';

  @Input()
  public userFullName = 'Eskei';

  @Input()
  public mainMenu: MenuItem[];

  @Input()
  public rightMenu: MenuItem[];

  constructor(private router: Router, private store: Store<State>) {}

  isActive(item: MenuItem): boolean {
    return this.router.url.search(item.routerLink) > -1;
  }

  ngOnInit(): void {
    this.logoLink = '/';
    this.mainMenu = [
      {
        id: 'home-item',
        title: 'Home',
        description: '',
        routerLink: '/home',
      },
      {
        id: 'blog-item',
        title: 'Blog',
        description: '',
        routerLink: '/blog',
      },
      {
        id: 'games-item',
        title: 'Our Work',
        description: '',
        routerLink: '/games',
      },
      {
        id: 'about-item',
        title: 'About',
        description: '',
        routerLink: '/about',
      },
      {
        id: 'contact-item',
        title: 'Contact',
        description: '',
        routerLink: '/contact',
      },
    ];
    this.rightMenu = [
      // {
      //   id: 'user-item',
      //   title: this.userFullName,
      //   description: '',
      //   children: [
      //     {
      //       id: 'profile-item',
      //       title: 'Your Profile',
      //       description:
      //         'See and update your profile.',
      //       routerLink: '/profile'
      //     },
      //     {
      //       id: 'log-off-item',
      //       title: 'Log off',
      //       description: '',
      //       routerLink: '',
      //       callback: () => alert("Logged out")
      //     }
      //   ]
      // }
    ];
  }

  ngAfterViewInit() {
    this.triggerToggle('trigger-click', TriggerEvent.click);
    this.menuMouseTracker();
    this.menuSlideResizer();
  }

  getDropdownClass(item: MenuItem) {
    return item.children && item.children.length > 0
      ? 'dropdown-container'
      : '';
  }

  triggerToggle(triggerClass: string, triggerEvent: TriggerEvent) {
    const triggers = document.querySelectorAll('.' + triggerClass);
    triggers.forEach((trigger: HTMLElement, index: number) => {
      trigger[triggerEvent] = (e) => {
        if (!trigger.classList.contains('trigger-open')) {
          this.collapseTopItems(triggerClass);
        }
        if (trigger.dataset.target) {
          const target = document.querySelector(
            '#' + trigger.dataset.target
          ) as HTMLElement;
          target.classList.toggle('target-active');
          const body = document.querySelector('body') as HTMLElement;
          // body.classList.toggle('branding-open');
        }
        if (trigger.dataset.container) {
          const container = trigger.closest('.' + trigger.dataset.container);
          if (container) {
            container.classList.toggle('container-open');
          }
        }
        trigger.classList.toggle('trigger-open');
        const body = document.querySelector('body') as HTMLElement;
        body.classList.toggle('branding-open');
        this.toggleUIState();
        e.preventDefault();
      };
    });
  }

  collapseTopItems(triggerClass: string) {
    const triggers = document.querySelectorAll('.top-item .' + triggerClass);
    triggers.forEach((trigger: HTMLElement, index: number) => {
      if (trigger.dataset.target) {
        const target = document.querySelector(
          '#' + trigger.dataset.target
        ) as HTMLElement;
        target.classList.remove('r');
        const body = document.querySelector('body') as HTMLElement;
        body.classList.remove('branding-open');
        this.toggleUIState();
      }
      if (trigger.dataset.container) {
        const container = trigger.closest('.' + trigger.dataset.container);
        if (container) {
          container.classList.remove('container-open');
        }
        const body = document.querySelector('body') as HTMLElement;
      }
      trigger.classList.remove('trigger-open');
    });
  }

  menuMouseTracker() {
    const megaMenus = document.querySelectorAll('.mega-menu');
    megaMenus.forEach((megaMenu: HTMLElement, index: number) => {
      megaMenu[TriggerEvent.hover] = (e) => {
        if (!megaMenu.classList.contains('menu-active')) {
          megaMenu.classList.add('menu-active');
        }
      };
      megaMenu[TriggerEvent.leave] = (e) => {
        if (megaMenu.classList.contains('menu-active')) {
          megaMenu.classList.remove('menu-active');
        }
        const megaMenuSlider = document.querySelector(
          '.mega-menu-slide'
        ) as HTMLElement;
        megaMenuSlider.style.height = '0px';
      };
    });
  }
  menuSlideResizer() {
    const triggers = document.querySelectorAll('.top-item, .navbar-branding');
    triggers.forEach((trigger: HTMLElement, index: number) => {
      trigger[TriggerEvent.hover] = (e) => {
        let dropdownMenuHeight = 0;
        if (trigger.children[1]) {
          dropdownMenuHeight = (trigger.children[1] as HTMLElement)
            .offsetHeight;
        }
        console.log(dropdownMenuHeight);

        const megaMenuSlider = document.querySelector(
          '.mega-menu-slide'
        ) as HTMLElement;
        megaMenuSlider.style.height = dropdownMenuHeight + 'px';
      };
    });
    const fillers = document.querySelectorAll('.filler');
    fillers.forEach((filler: HTMLElement, index: number) => {
      filler[TriggerEvent.hover] = (e) => {
        const megaMenuSlider = document.querySelector(
          '.mega-menu-slide'
        ) as HTMLElement;
        if (megaMenuSlider.offsetHeight > 0) {
          megaMenuSlider.style.height = '0px';
        }
      };
    });
  }

  closeMenu() {
    const target = document.querySelector('#navbarSupportedContent');
    const toggle = document.querySelector('.menu-toggle');
    target.classList.toggle('target-active');
    toggle.classList.toggle('trigger-open');
    const body = document.querySelector('body') as HTMLElement;
    body.classList.toggle('branding-open');
    this.toggleUIState();
  }

  private toggleUIState() {
    const body = document.querySelector('body') as HTMLElement;
    if (body.classList.contains('branding-open')) {
      this.store.dispatch(DisableUI()());
    } else {
      this.store.dispatch(EnableUI()());
    }
  }
}

enum TriggerEvent {
  click = 'onclick',
  hover = 'onmouseover',
  leave = 'onmouseleave',
}
