<ng-container>
  <div class="loading" [style.display]="loading ? 'block' : 'none'"></div>
  <div
    class="bg-wrapper"
    [ngClass]="getState(o)"
    [class.ui-disabled]="!(isInteractive$ | async)"
    (click)="closeMenu($event)"
  >
    <app-menu class="d-block" [ngClass]="getMenuClass(o)"></app-menu>
    <div id="main-content">
      <div class="router-wrapper" [@moveFromBottom]="getState(o)">
        <router-outlet #o="outlet"> </router-outlet>
      </div>
    </div>
  </div>
</ng-container>
