import { createAction } from '@ngrx/store';

export enum AppActionType {
  EnableUI = '[AppActionType] Enable UI',
  DisableUI = '[AppActionType] Disable UI',
}

export function EnableUI() {
  return createAction(AppActionType.EnableUI);
}

export function DisableUI() {
  return createAction(AppActionType.DisableUI);
}
