<div class="row">
  <div class="col-1 offset-3">
    <img
      [src]="avatar"
      class="float-right img-fluid w-100"
      alt="{{ author.name }}'s picture"
    />
  </div>
  <div class="col-5">
    <h4 class="text-uppercase font-weight-bold h5 mb-0">Author</h4>
    <h3 class="text-uppercase font-weight-bold h3 text-white">
      {{ author?.name }}
    </h3>

    <div class="rich-content">
      <p>{{ author?.description }}</p>
    </div>
  </div>
</div>
