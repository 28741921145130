<nav class="navbar p-0 font-weight-bold text-uppercase mega-menu">
  <div class="mega-menu-slide"></div>
  <div
    class="navbar-branding d-flex align-items-center justify-content-between trigger-hover"
  >
    <a class="navbar-brand logo mr-lg-5" [routerLink]="[logoLink]">
      <!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In  -->
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="1091.51px"
        height="357.21px"
        viewBox="0 0 1091.51 357.21"
        style="overflow: visible; enable-background: new 0 0 1091.51 357.21"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #57cccb;
          }
        </style>
        <defs></defs>
        <path
          class="st0"
          d="M176.23,0.02C78.68,1.28,0,80.75,0,178.6c0,83.17,56.84,153.06,133.78,172.93c0.01,0.01,0.04,0.01,0.05,0.03
c14.32,3.68,29.3,5.65,44.77,5.65c2.64,0,5.27-0.05,7.89-0.18c7.22,0.04,14.42-0.54,21.28-2.2c43.67-10.53,73.78-53.74,73.95-101.03
c0.21-58.38-47.41-106.5-105.79-106.87c-28.46-0.18-54.34,10.8-73.54,28.83c21.54-28.52,56.31-46.52,95.19-44.91
c58.83,2.44,106.41,50.46,108.35,109.3c1.49,45.32-23.66,84.92-60.99,104.33c66.12-26.46,112.74-91.32,112.27-167.01
C356.59,78.75,274.94-1.27,176.23,0.02z"
        />
        <g>
          <g>
            <path
              class="st0"
              d="M483.65,66.04c-1.24-1.38-2.62-2.6-4.14-3.65c-1.53-1.05-3.16-1.92-4.91-2.62c-1.74-0.69-3.64-1.22-5.67-1.58
   c-2.04-0.36-4.22-0.55-6.54-0.55c-7.56,0-13.07,1.43-16.52,4.3c-3.45,2.87-5.18,6.73-5.18,11.59c0,3.27,0.78,5.86,2.34,7.79
   c1.56,1.92,4.02,3.47,7.36,4.63c3.34,1.16,7.67,2.28,12.98,3.37c5.6,1.16,10.43,2.52,14.5,4.08c4.07,1.56,7.21,3.68,9.43,6.37
   c2.22,2.68,3.32,6.31,3.32,10.89c0,3.41-0.65,6.4-1.96,8.98c-1.31,2.58-3.2,4.7-5.67,6.37c-2.47,1.67-5.4,2.94-8.78,3.81
   c-3.38,0.87-7.14,1.31-11.29,1.31c-4,0-7.81-0.44-11.45-1.31c-3.64-0.87-7.05-2.14-10.25-3.82c-3.2-1.67-6.18-3.82-8.94-6.43
   l2.94-4.47c1.6,1.74,3.4,3.29,5.4,4.63c2,1.35,4.18,2.49,6.54,3.43c2.36,0.95,4.89,1.69,7.58,2.24c2.69,0.55,5.49,0.82,8.4,0.82
   c6.76,0,12.05-1.25,15.86-3.75c3.82-2.5,5.72-6.22,5.72-11.16c0-3.41-0.87-6.17-2.62-8.27c-1.74-2.1-4.44-3.83-8.07-5.17
   c-3.64-1.34-8.18-2.59-13.63-3.76c-5.45-1.16-10.09-2.47-13.9-3.92c-3.82-1.45-6.69-3.41-8.61-5.88
   c-1.93-2.47-2.89-5.81-2.89-10.02c0-4.64,1.14-8.58,3.43-11.81c2.29-3.23,5.51-5.68,9.65-7.35c4.14-1.67,8.9-2.5,14.28-2.5
   c3.34,0,6.45,0.33,9.32,0.98c2.87,0.65,5.52,1.66,7.96,3c2.43,1.35,4.71,3.04,6.81,5.07L483.65,66.04z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M558.34,58.19h-28.35v72.4h-5.56v-72.4h-28.35v-5.02h62.26V58.19z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M601.52,131.14c-6.18,0-11.34-1.09-15.48-3.27c-4.14-2.18-7.45-5.14-9.92-8.89
   c-2.47-3.74-4.25-7.94-5.34-12.6c-1.09-4.65-1.64-9.41-1.64-14.28V53.18h5.56V92.1c0,4.22,0.44,8.36,1.31,12.43
   c0.87,4.07,2.31,7.72,4.31,10.96c2,3.24,4.72,5.8,8.18,7.69c3.45,1.89,7.76,2.84,12.92,2.84c5.23,0,9.58-0.98,13.03-2.95
   c3.45-1.96,6.18-4.56,8.18-7.8c2-3.23,3.42-6.87,4.25-10.9c0.83-4.04,1.25-8.13,1.25-12.27V53.18h5.56V92.1
   c0,5.16-0.56,10.09-1.69,14.78c-1.13,4.69-2.96,8.85-5.51,12.48c-2.55,3.64-5.85,6.51-9.92,8.61
   C612.49,130.09,607.48,131.14,601.52,131.14z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M652.76,130.59V53.18h25.73c8.21,0,15.03,1.73,20.44,5.18c5.41,3.45,9.49,8.11,12.21,13.96
   c2.73,5.85,4.09,12.34,4.09,19.46c0,7.85-1.49,14.68-4.47,20.5c-2.98,5.82-7.23,10.32-12.76,13.52c-5.52,3.2-12.03,4.8-19.52,4.8
   H652.76z M709.68,91.78c0-6.47-1.22-12.25-3.65-17.34c-2.44-5.09-5.96-9.07-10.58-11.94c-4.62-2.87-10.27-4.31-16.96-4.31h-20.17
   v67.38h20.17c6.76,0,12.47-1.49,17.12-4.47c4.65-2.98,8.16-7.03,10.52-12.16C708.5,103.83,709.68,98.1,709.68,91.78z"
            />
          </g>
          <g>
            <path class="st0" d="M730.51,130.59V53.18h5.56v77.42H730.51z" />
          </g>
          <g>
            <path
              class="st0"
              d="M787.2,131.14c-5.38,0-10.29-1.11-14.72-3.33c-4.44-2.22-8.25-5.18-11.45-8.89c-3.2-3.71-5.67-7.9-7.41-12.59
   c-1.74-4.69-2.62-9.5-2.62-14.45c0-5.16,0.91-10.09,2.73-14.77c1.82-4.69,4.38-8.87,7.69-12.54c3.31-3.67,7.16-6.58,11.56-8.72
   c4.4-2.14,9.18-3.22,14.34-3.22c5.45,0,10.38,1.13,14.77,3.38c4.4,2.25,8.2,5.27,11.39,9.05c3.2,3.78,5.67,8,7.41,12.65
   c1.74,4.65,2.62,9.38,2.62,14.17c0,5.24-0.91,10.19-2.73,14.88c-1.82,4.69-4.38,8.87-7.69,12.54c-3.31,3.67-7.16,6.56-11.56,8.67
   C797.14,130.09,792.36,131.14,787.2,131.14z M756.57,91.89c0,4.43,0.76,8.69,2.29,12.76c1.53,4.07,3.65,7.71,6.38,10.9
   c2.73,3.2,5.96,5.74,9.7,7.63c3.74,1.89,7.87,2.84,12.38,2.84c4.58,0,8.76-0.98,12.54-2.95c3.78-1.96,7.01-4.58,9.7-7.85
   c2.69-3.27,4.74-6.92,6.16-10.96c1.42-4.04,2.13-8.16,2.13-12.38c0-4.43-0.75-8.69-2.24-12.76c-1.49-4.07-3.62-7.7-6.38-10.9
   c-2.76-3.2-6.01-5.74-9.76-7.63c-3.74-1.89-7.8-2.83-12.16-2.83c-4.65,0-8.87,0.98-12.65,2.94c-3.78,1.96-7,4.58-9.65,7.85
   c-2.65,3.27-4.73,6.92-6.22,10.96C757.31,83.54,756.57,87.67,756.57,91.89z"
            />
          </g>
          <path
            class="st0"
            d="M434.16,265.44V165.05c0-8,6.49-14.49,14.49-14.49h31.43c13.97,0,25.63,2.89,34.98,8.65
 c9.35,5.77,16.37,13.54,21.04,23.32c4.67,9.78,7.01,20.62,7.01,32.52c0,13.12-2.58,24.54-7.74,34.25
 c-5.16,9.72-12.48,17.25-21.95,22.59c-9.47,5.35-20.59,8.02-33.34,8.02h-31.43C440.65,279.93,434.16,273.44,434.16,265.44z
  M522.34,215.06c0-9.11-1.67-17.16-5.01-24.14c-3.34-6.98-8.14-12.45-14.39-16.4c-6.26-3.94-13.88-5.92-22.87-5.92h-11.02
 c-8,0-14.49,6.49-14.49,14.49v64.31c0,8,6.49,14.49,14.49,14.49h11.02c9.11,0,16.79-2.06,23.05-6.19
 c6.25-4.13,11.02-9.72,14.3-16.76C520.7,231.89,522.34,223.93,522.34,215.06z"
          />
          <path
            class="st0"
            d="M623.28,280.84c-9.85,0-18.74-1.82-26.67-5.47c-7.93-3.64-14.77-8.59-20.53-14.85
 c-5.76-6.25-10.2-13.3-13.33-21.13c-3.14-7.84-4.7-15.88-4.7-24.14c0-8.62,1.66-16.85,4.99-24.69c3.32-7.84,7.93-14.82,13.81-20.95
 c5.88-6.13,12.82-10.99,20.81-14.58c7.99-3.58,16.66-5.37,25.99-5.37c9.72,0,18.57,1.89,26.57,5.65
 c7.99,3.77,14.83,8.81,20.53,15.12c5.69,6.32,10.1,13.36,13.24,21.13c3.13,7.77,4.7,15.73,4.7,23.87c0,8.63-1.63,16.85-4.89,24.69
 c-3.26,7.84-7.84,14.82-13.72,20.95c-5.88,6.13-12.82,10.96-20.81,14.48C641.28,279.08,632.61,280.84,623.28,280.84z
  M579.92,215.25c0,6.08,1.02,11.97,3.07,17.67c2.04,5.71,4.96,10.78,8.73,15.21c3.77,4.44,8.34,7.92,13.72,10.48
 c5.37,2.55,11.38,3.83,18.03,3.83c6.91,0,13.05-1.34,18.42-4.01c5.37-2.67,9.88-6.29,13.52-10.84c3.65-4.56,6.43-9.66,8.35-15.3
 c1.92-5.65,2.88-11.32,2.88-17.04c0-6.07-0.99-11.93-2.97-17.58c-1.98-5.65-4.89-10.69-8.73-15.12
 c-3.84-4.43-8.41-7.93-13.72-10.48c-5.31-2.55-11.22-3.83-17.75-3.83c-6.91,0-13.04,1.34-18.42,4.01
 c-5.37,2.67-9.91,6.26-13.62,10.75c-3.71,4.49-6.56,9.54-8.54,15.12C580.91,203.71,579.92,209.42,579.92,215.25z"
          />
          <path
            class="st0"
            d="M815.32,263.51v-75.78l-38.08,69.96h-12.03l-38.26-69.96v108.61c0,0-5.2,4.18-6.93,5.58l-9.22,7.42
 c-1.71,1.38-4.25,0.16-4.25-2.04V159.52c0-4.94,4.01-8.95,8.95-8.95h12.91l42.82,79.07l42.82-79.07h21.86v119.66
 c0,5.35-4.34,9.7-9.7,9.7L815.32,263.51z"
          />
          <path
            class="st0"
            d="M918.8,280.84c-9.85,0-18.74-1.82-26.67-5.47c-7.93-3.64-14.77-8.59-20.53-14.85
 c-5.76-6.25-10.2-13.3-13.33-21.13c-3.14-7.84-4.7-15.88-4.7-24.14c0-8.62,1.66-16.85,4.99-24.69c3.32-7.84,7.93-14.82,13.81-20.95
 c5.88-6.13,12.82-10.99,20.81-14.58c7.99-3.58,16.66-5.37,25.99-5.37c9.72,0,18.57,1.89,26.57,5.65
 c7.99,3.77,14.83,8.81,20.53,15.12c5.69,6.32,10.1,13.36,13.24,21.13c3.13,7.77,4.7,15.73,4.7,23.87c0,8.63-1.63,16.85-4.89,24.69
 c-3.26,7.84-7.83,14.82-13.72,20.95c-5.88,6.13-12.82,10.96-20.81,14.48C936.8,279.08,928.13,280.84,918.8,280.84z M875.44,215.25
 c0,6.08,1.02,11.97,3.07,17.67c2.04,5.71,4.96,10.78,8.73,15.21c3.77,4.44,8.34,7.92,13.72,10.48c5.37,2.55,11.38,3.83,18.03,3.83
 c6.91,0,13.05-1.34,18.42-4.01c5.37-2.67,9.88-6.29,13.52-10.84c3.65-4.56,6.43-9.66,8.35-15.3c1.92-5.65,2.88-11.32,2.88-17.04
 c0-6.07-0.99-11.93-2.97-17.58c-1.98-5.65-4.89-10.69-8.73-15.12c-3.84-4.43-8.41-7.93-13.72-10.48
 c-5.31-2.55-11.22-3.83-17.75-3.83c-6.91,0-13.04,1.34-18.42,4.01c-5.37,2.67-9.91,6.26-13.62,10.75
 c-3.71,4.49-6.56,9.54-8.54,15.12C876.43,203.71,875.44,209.42,875.44,215.25z"
          />
          <path
            class="st0"
            d="M1089.87,273.36h-81.24c-3.63,0-6.57-2.94-6.57-6.57V165.34c0-4.53,3.67-8.21,8.21-8.21h79.6
 c0.91,0,1.64,0.73,1.64,1.64v14.75c0,0.91-0.73,1.64-1.64,1.64h-63.32c-2.25,0-4.08,1.83-4.08,4.08v20.44
 c0,2.25,1.83,4.08,4.08,4.08h45.58c0.91,0,1.64,0.73,1.64,1.64v15.3c0,0.91-0.73,1.64-1.64,1.64h-45.58
 c-2.25,0-4.08,1.83-4.08,4.08v24.81c0,2.25,1.83,4.08,4.08,4.08h63.32c0.91,0,1.64,0.73,1.64,1.64v14.76
 C1091.51,272.63,1090.78,273.36,1089.87,273.36z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M481.94,68.84c-0.86-1.01-2.02-1.98-3.46-2.92c-1.44-0.94-3.06-1.78-4.86-2.54c-1.8-0.76-3.76-1.37-5.89-1.84
 c-2.12-0.47-4.34-0.7-6.64-0.7c-5.9,0-10.26,1.11-13.07,3.34c-2.81,2.23-4.21,5.28-4.21,9.15c0,2.73,0.72,4.88,2.16,6.46
 c1.44,1.58,3.65,2.87,6.64,3.88c2.99,1.01,6.79,2.05,11.39,3.12c5.33,1.15,9.94,2.55,13.82,4.2c3.89,1.65,6.88,3.86,8.96,6.62
 c2.09,2.76,3.13,6.44,3.13,11.03c0,3.66-0.7,6.84-2.11,9.53c-1.4,2.69-3.37,4.92-5.89,6.68c-2.52,1.76-5.47,3.05-8.86,3.88
 c-3.39,0.82-7.06,1.24-11.02,1.24c-3.89,0-7.69-0.42-11.39-1.24c-3.71-0.83-7.22-2.03-10.53-3.62c-3.31-1.58-6.41-3.56-9.29-5.94
 l4.64-8.21c1.15,1.23,2.65,2.43,4.48,3.62c1.84,1.19,3.92,2.29,6.26,3.29c2.34,1.01,4.88,1.82,7.61,2.43
 c2.74,0.61,5.54,0.92,8.42,0.92c5.47,0,9.72-0.98,12.74-2.95c3.02-1.97,4.54-4.86,4.54-8.65c0-2.87-0.85-5.17-2.54-6.89
 c-1.69-1.72-4.2-3.16-7.51-4.31c-3.31-1.15-7.34-2.3-12.1-3.45c-5.18-1.29-9.54-2.71-13.07-4.25c-3.53-1.54-6.17-3.57-7.94-6.08
 c-1.77-2.51-2.65-5.78-2.65-9.8c0-4.88,1.19-9.01,3.56-12.38s5.65-5.92,9.83-7.65c4.17-1.72,8.89-2.59,14.15-2.59
 c3.46,0,6.68,0.36,9.67,1.08c2.99,0.72,5.78,1.73,8.37,3.02c2.59,1.3,4.97,2.85,7.13,4.64L481.94,68.84z"
          />
          <path
            class="st0"
            d="M557.97,61.39h-26.46v68.04h-9.72V61.39h-26.46v-8.64h62.64V61.39z"
          />
          <path
            class="st0"
            d="M600.63,129.97c-6.12,0-11.27-1.06-15.44-3.19c-4.18-2.12-7.52-5-10.04-8.64c-2.52-3.63-4.36-7.76-5.51-12.37
 c-1.15-4.61-1.73-9.36-1.73-14.26V52.74h9.72v38.77c0,3.74,0.38,7.4,1.13,10.96s2,6.75,3.73,9.56c1.73,2.81,4.07,5.04,7.02,6.7
 c2.95,1.66,6.62,2.48,11.02,2.48c4.46,0,8.17-0.84,11.12-2.54c2.95-1.69,5.29-3.96,7.02-6.8c1.73-2.84,2.97-6.03,3.73-9.56
 c0.76-3.53,1.13-7.13,1.13-10.8V52.74h9.72v38.77c0,5.18-0.59,10.1-1.78,14.74c-1.19,4.64-3.08,8.73-5.67,12.26
 c-2.59,3.53-5.96,6.32-10.1,8.37C611.55,128.94,606.53,129.97,600.63,129.97z"
          />
          <path
            class="st0"
            d="M650.52,129.43V52.74h26.57c8.21,0,15.07,1.71,20.57,5.13c5.51,3.42,9.63,8.03,12.37,13.82
 c2.74,5.8,4.1,12.22,4.1,19.28c0,7.78-1.51,14.54-4.54,20.3c-3.02,5.76-7.31,10.22-12.85,13.39c-5.55,3.17-12.1,4.75-19.66,4.75
 H650.52z M704.31,90.98c0-5.76-1.06-10.87-3.19-15.34c-2.12-4.46-5.2-7.95-9.23-10.48c-4.03-2.52-8.96-3.78-14.8-3.78h-16.85v59.4
 h16.85c5.9,0,10.87-1.31,14.9-3.94c4.03-2.63,7.09-6.19,9.18-10.69C703.26,101.65,704.31,96.59,704.31,90.98z"
          />
          <path class="st0" d="M727.85,129.43V52.74h9.72v76.68H727.85z" />
          <path
            class="st0"
            d="M787.03,129.97c-5.4,0-10.3-1.08-14.69-3.24c-4.39-2.16-8.19-5.09-11.39-8.8c-3.21-3.71-5.67-7.88-7.4-12.53
 c-1.73-4.64-2.59-9.41-2.59-14.31c0-5.11,0.92-9.99,2.75-14.63s4.39-8.78,7.67-12.42c3.28-3.63,7.11-6.52,11.5-8.64
 c4.39-2.12,9.18-3.19,14.36-3.19c5.4,0,10.3,1.12,14.69,3.35c4.39,2.23,8.17,5.22,11.34,8.96c3.17,3.74,5.62,7.92,7.34,12.53
 c1.73,4.61,2.59,9.33,2.59,14.15c0,5.11-0.9,9.99-2.7,14.63c-1.8,4.64-4.34,8.79-7.61,12.42c-3.28,3.64-7.11,6.5-11.5,8.59
 C797,128.92,792.22,129.97,787.03,129.97z M760.79,91.09c0,3.89,0.63,7.65,1.89,11.29c1.26,3.64,3.04,6.86,5.35,9.67
 c2.3,2.81,5.08,5.04,8.32,6.7s6.84,2.48,10.8,2.48c4.1,0,7.78-0.86,11.02-2.59s5.99-4.03,8.26-6.91c2.27-2.88,4-6.12,5.18-9.72
 c1.19-3.6,1.78-7.24,1.78-10.91c0-3.89-0.63-7.63-1.89-11.23c-1.26-3.6-3.06-6.82-5.4-9.67c-2.34-2.84-5.11-5.08-8.32-6.7
 c-3.21-1.62-6.75-2.43-10.64-2.43c-4.1,0-7.78,0.85-11.02,2.54c-3.24,1.69-5.99,3.98-8.26,6.86c-2.27,2.88-4.01,6.1-5.24,9.67
 C761.4,83.69,760.79,87.34,760.79,91.09z"
          />
        </g>
      </svg>
    </a>
    <button
      class="trigger-click btn btn-link menu-toggle"
      type="button"
      [attr.data-target]="'navbarSupportedContent'"
      aria-controls="navbarSupportedContent"
      aria-label="Toggle navigation"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  </div>

  <div
    class="collapse navbar-collapse text-right mt-0"
    id="navbarSupportedContent"
  >
    <div class="container mt-5 pt-4">
      <ul class="navbar-nav">
        <div class="logo-bg"></div>
        <ng-container *ngFor="let item of mainMenu">
          <li class="top-item {{ getDropdownClass(item) }} overflow-hidden">
            <div
              [ngClass]="{
                'trigger-click': item.children && item.children.length > 0,
                active: isActive(item)
              }"
              [attr.data-target]="'dropdown-' + item.id"
              [attr.data-container]="'dropdown-container'"
            >
              <app-menu-item
                [item]="item"
                (click)="closeMenu()"
              ></app-menu-item>
            </div>
            <ul
              *ngIf="item.children && item.children.length > 0"
              class="list-unstyled row menu-dropdown"
              id="dropdown-{{ item.id }}"
            >
              <li *ngFor="let card of item.children; let i = index">
                <app-card-item [card]="card"></app-card-item>
              </li>
            </ul>
          </li>
        </ng-container>
        <li class="filler"></li>
      </ul>
    </div>

    <ul class="navbar-nav ml-auto d-none">
      <li class="filler"></li>
      <ng-container *ngFor="let item of rightMenu">
        <li class="top-item {{ getDropdownClass(item) }} overflow-hidden">
          <div
            [ngClass]="{
              'trigger-click': item.children && item.children.length > 0,
              active: isActive(item)
            }"
            [attr.data-target]="'dropdown-' + item.id"
            [attr.data-container]="'dropdown-container'"
          >
            <app-menu-item [item]="item"></app-menu-item>
          </div>
          <ul
            *ngIf="item.children && item.children.length > 0"
            class="list-unstyled row menu-dropdown"
            id="dropdown-{{ item.id }}"
          >
            <li *ngFor="let card of item.children; let i = index">
              <app-card-item [card]="card"></app-card-item>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>
