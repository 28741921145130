import { Action, ActionReducerMap, createReducer, MetaReducer, on } from '@ngrx/store';

import { environment } from '../../environments/environment';
import { DisableUI, EnableUI } from './actions';

export interface State {
  isInteractive: boolean;
}

export const reducers: ActionReducerMap<State> = {
  isInteractive: createReducer<boolean, Action>(
    true,
    on(EnableUI(), () => true),
    on(DisableUI(), () => false)
  ),
};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];
