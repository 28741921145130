import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthorComponent } from './author/author.component';
import { MenuComponent } from './menu/menu.component';
import { MenuItemComponent } from './menu/menu-item/menu-item.component';
import { CardItemComponent } from './menu/card-item/card-item.component';

@NgModule({
  declarations: [
    AuthorComponent,
    MenuComponent,
    MenuItemComponent,
    CardItemComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [
    AuthorComponent,
    MenuComponent,
    MenuItemComponent,
    CardItemComponent,
  ],
})
export class UtilsModule {}
