import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from '../menu.component';

@Component({
  selector: 'app-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss'],
})
export class CardItemComponent {
  @Input() card: MenuItem;

  constructor() {}
}
