import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/blog/wordpress.model';

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.scss'],
})
export class AuthorComponent implements OnInit {
  @Input() author: User;
  avatar: string;
  constructor() {}

  ngOnInit(): void {
    if (this.author) {
      this.avatar = Object.values(this.author.avatar_urls).pop() as any;
    }
  }
}
