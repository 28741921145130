// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://www.domoe.net/cms/wp-json/wp/v2/',
  postsUrl: 'posts/',
  categoriesUrl: 'categories/',
  tagsUrl: 'tags/',
  usersUrl: 'users/',
  pagesUrl: 'pages/',
  menusUrl: 'nav_menu_item/',
  mediaUrl: 'media/',
  strapiUrl: 'https://159.65.34.127/',
  aboutPageUrl: 'about-page',
  contactPageUrl: 'contact',
  ourWorkPageUrl: 'our-work',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
