<ng-container *ngIf="card">
  <ng-template #cardContents>
    <div class="item-card">
      <div class="item-card-body">
        <span
          class="item-card-title d-block h4"
          [innerHtml]="card.title"
        ></span>
        <span
          class="item-card-text d-none"
          [innerHtml]="card.description"
        ></span>
      </div>
    </div>
  </ng-template>
  <a *ngIf="!(card.routerLink || card.externalLink || card.callback)" href="#">
    <ng-container *ngTemplateOutlet="cardContents"></ng-container>
  </a>
  <a *ngIf="card.routerLink" [routerLink]="[card.routerLink]">
    <ng-container *ngTemplateOutlet="cardContents"></ng-container>
  </a>
  <a *ngIf="card.externalLink" [href]="card.routerLink">
    <ng-container *ngTemplateOutlet="cardContents"></ng-container>
  </a>
  <a *ngIf="card.callback" (click)="card.callback()">
    <ng-container *ngTemplateOutlet="cardContents"></ng-container>
  </a>
</ng-container>
