<ng-container *ngIf="item">
    <a *ngIf="!(item.routerLink || item.externalLink || item.callback)" href="#" class="item-text" [innerHtml]="item.title"></a>
    <a
      *ngIf="item.routerLink"
      class="item-text"
      [routerLink]="[item.routerLink]"
      [innerHtml]="item.title"
      ></a
    >
    <a
      *ngIf="item.externalLink"
      [href]="item.routerLink"
      class="item-text"
      [innerHtml]="item.title"
      ></a
    >
    <a
      *ngIf="item.callback"
      (click)="item.callback()"
      class="item-text"
      [innerHtml]="item.title"
      ></a
    >
</ng-container>
